import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, get } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import Divider from '../../components/Divider';
import CourseCard from '../../components/CourseCard';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import SEO from '../../components/SEO/SEO';
import { CoursesQuery,StrapiPageQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './courses-index.module.scss';

type PageContextType = GlobalPageContext;

const CoursesIndex: React.FC<PageProps<CoursesQuery, PageContextType>> = ({ data, pageContext }) => {
  const { courses } = data.strapi;
  const pages = get(data, 'strapi.pages', []);
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const intl = useIntl();
  const page = get(data, 'strapi.pages[0]', {}) as StrapiPageQuery['strapi']['page'] | undefined;
  if (!websiteLocale) return null;
  
  const showFooterCta = page.page_settings?.showFooterCta ?? true;
  const footerCta = page.page_settings?.customFooterCta.id ? page.page_settings.customFooterCta : websiteLocale.footerCta;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ??  intl.formatMessage({ id: 'seo.courses.title' })}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.pageSettings?.metaDescription ?? intl.formatMessage({ id: 'seo.courses.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.root)}>
        <h1 className="title color-a">
          <FormattedMessage id="coursesIndexHeading" defaultMessage="The English course for you" />
        </h1>
        <div className="columns">
          <div className="column is-9-tablet">
            <p className="is-text-1 black-2">
              <FormattedMessage id="coursesIndexDescription" />
            </p>
          </div>
        </div>
        <Divider paddingTop="75px" marginBottom="25px" />
        <div className={styles.grid}>
          {compact(courses)?.map(({ name, short, image, slug }) => (
            <CourseCard
              linkText={intl.formatMessage({ id: 'coursesCardText', defaultMessage: 'View Course' })}
              key={name}
              name={name}
              short={short}
              image={image}
              slug={slug ?? ''}
            />
          ))}
        </div>
      </div>
      <div className={classnames('page-components')}>
        {pages[0]?.components &&
          pages[0]?.components?.map((component: any, idx: any) =>
            component ? (
              <ComponentRenderer
                hasRTL={websiteLocale?.hasRightToLeftLanguage}
                component={component}
                key={`page-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
      </div>
      {/* {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />} */}
      <div className={styles.footercta_space}>
        {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
      </div>
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(CoursesIndex));

export const query = graphql`
query Courses($websiteLocaleId: ID!, $limit: Int!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    courses(
      filters: {website_locale: {id: {eq: $websiteLocaleId}}}
      sort: "original_date:asc"
      pagination: {limit: $limit}
      locale: $locale
    ) {
      data {
        id
        attributes {
          name
          slug
          short
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          courseFeatures: course_features {
            feature
          }
          image {
            ...StrapiImageEntityResponse
          }
        }
      }
    }
    pages(
      filters: {website_locale: {id: {eq: $websiteLocaleId}}, index_page: {eq: "Courses"}}
      locale:$locale
    ) {
      data {
        id
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;
