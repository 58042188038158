import React, { useContext } from 'react';
import classnames from 'classnames';
import path from 'path-browserify';

import StrapiLinkUnstyled from '../StrapiLink/StrapiLinkUnstyled';
import { PageContext } from '../PageContext';
import StrapiLink from '../StrapiLink/StrapiLink';
import { StrapiImageFragment, Maybe } from '../../../graphql.schema';

import * as styles from './course-card.module.scss';
import { useImageResize } from '../../hooks/useImageResize';
import { fullImageUrl } from '../../lib/helpers';

type CourseCardProps = {
  name: string;
  image?: Maybe<StrapiImageFragment>;
  short: string;
  slug: string;
  linkText: string;
  className?: string;
};

const CourseCard = ({ className, name, image, short, slug, linkText }: CourseCardProps) => {
  const { pathPrefixes } = useContext(PageContext);
  const { imageUrl, imageWrapper } = useImageResize(image);

  return (
    <div className={classnames(styles.root, className, 'is-flex is-align-items-center bg-black-5 h-100')} data-testid="courseCard">
      <div ref={imageWrapper} className={classnames(styles.image, 'h-100')}>
        <img
          alt={image?.alternativeText ?? image?.caption ?? ''}
          src={fullImageUrl(imageUrl)}
          loading="lazy"
          className="h-100"
          width={image?.width}
          height={image?.height}
        />
      </div>
      <div className={styles.panel}>
        <h3 className="title is-3 brand-a mb-1">
          <StrapiLinkUnstyled className={styles.title} url={path.join(pathPrefixes.courses, slug)} data-testid="courseCardLink">
            {name}
          </StrapiLinkUnstyled>
        </h3>
        <p className="is-text-2 mb-2">{short}</p>
        <StrapiLink url={path.join(pathPrefixes.courses, slug)} title={linkText} data-testid="courseCardLinkText"/>
      </div>
    </div>
  );
};

export default CourseCard;
